<template>
  <div class="ml-5 mr-5">
    <div class="text-right my-4">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
      <template>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="mx-2" color="primary" dark v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('add')">
              Add
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Role</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit="addRoleData"
              method="post"
              v-model="valid"
              lazy-validation
            >
              <v-container class="px-50 v-card-padding" fluid>
                <v-text-field
                  v-model="addRole.roleName"
                  label="Role Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <div style="color: red">
                  {{ message1 }}
                </div>
                <v-text-field
                  v-model="addRole.role_summary"
                  label="Add Role Summary"
                ></v-text-field>
              </v-container>
              <v-container class="px-50" fluid> </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" type="submit" @click="validate">
                  Submit
                </v-btn>
                <v-btn class="close_button mx-2" text @click="reset"> Close </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
      <v-dialog max-width="600" v-model="dialog2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="error" small v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('delete')">Delete</v-btn>
        </template>
        <div v-if="deleteItems.length > 0">
          <template>
            <v-card>
              <v-card-text>
                <div class="text-h3 pa-12">
                  Are you sure you want to delete all the selected Record(s)?
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                  <v-btn text class="confirm_button" v-on:click="dialog2 = false" @click="deleteRole"
                    >Yes</v-btn
                  >
                </v-form>
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
        <div v-if="deleteItems.length == 0">
          <template>
            <v-card>
              <v-card-text class="center">
                <div class="text-h3 pa-12">
                  Please Select Record(s) to delete
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn class="close_button" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
      </v-dialog>
    </div>
    <div class="text-right"></div>

<!-- Edit action -->
  <v-row justify="center">
     <v-dialog
      v-model="toggleUpdateModal"
      max-width="600"
    >
      <template>
	  <v-card>
      <v-card-title>
        <span class="headline">Update Role</span>
      </v-card-title>
      <v-form
        ref="form"
        @submit="updateRoleData"
        method="put"
      >
        <v-container class="px-50" fluid>
          <v-text-field
            v-model="updateRole.roleName"
            label="Role Name"
            required
            :rules="nameRules"
          ></v-text-field>
          <v-text-field
            v-model="updateRole.roleDescription"
            label="Role Description"
            required
            :rules="nameRules"
            ></v-text-field>
          <div style="color: red">
            {{ upmessage1 }}
          </div>
        </v-container>
        <v-container class="px-50" fluid> </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            @click="updateRoleData"
            type="submit"
          >
            Update
          </v-btn>
          <v-btn class="close_button mx-2" text @click="toggleUpdateModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
	  </template>
     </v-dialog>
  </v-row>
<!-- Edit action ends -->

<!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <div id="select-all-checkbox" v-show="checkIfOperationExistForModule('add')">
              <v-checkbox
                ref="n"
                v-model="allSelected"
                color="default"
                @click="selectAll"
              ></v-checkbox>
            </div>
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :items="role_data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:item.selectCheckbox="{ item }">
                <v-checkbox
                  ref="n"
                  v-model="deleteItems"
                  :value="item.role_id"
                  v-if="item.role_id!=1"
                  :key="item.role_id"
                  v-show="checkIfOperationExistForModule('add')"
                  @click="addToDelete($event, item.role_id)"
                ></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  medium
                  color="grey"
                  v-if="item.role_id!=1"
                  class="mdi-24px"
                  v-show="checkIfOperationExistForModule('add')"
                    @click="$router.push({ path: 'permission-management/' + item.role_id });"
                  title="Module Permissions"
                >
                  mdi-alpha-m-circle
                </v-icon>
                <v-icon
                  medium
                  v-if="item.role_id!=1"
                  color="grey"
                  class="mdi-24px"
                  v-show="checkIfOperationExistForModule('add')"
                    @click="$router.push({ path: 'service-management/' + item.role_id });"
                  title="Endpoint Permissions"
                >
                  mdi-alpha-e-circle
                </v-icon>
                <v-icon
                  medium
                  color="grey"
                  v-if="item.role_id!=1"
                  class="mdi-24px"
                  v-show="checkIfOperationExistForModule('add')"
                  @click="$router.push({ path: 'role-product-taxonomy/' + item.role_id });"
                  title="Product Taxonomy"
                >
                  mdi-alpha-p-circle
                </v-icon>
              </template>
              <template v-slot:item.edit_action="{ item }">
                <v-dialog
                  v-model="dialog1"
                  persistent
                  :retain-focus="false"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon
                      medium
                      color="grey"
                      @click="addToUpdate($event, item.role_id)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>

                </v-dialog>
              </template>
            </v-data-table>
             <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  <!-- v-data-table-ends -->
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import Pagination from "./component/Pagination.vue";

export default {
  components: { Index, Buttons, Loading, Pagination },
  data() {
    return {
      //////table data starts here////
      toggleUpdateModal: false,
      selected: [],
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Role Name",
          value: "role_name",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Role Description",
          value: "role_description",
          width: "300px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Modified Date",
          value: "modified_date",
          width: "100px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Permissions",
          value: "actions",
          width: "100px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Action",
          value: "edit_action",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here

      valid: true,
      checkbox: false,
      roleName: [],
      role_data: [],
      isLoading: true,
      fullPage: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_roleId: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      addRole: {
        roleName: null,
        role_summary: null,
      },
      updateRole: {
        roleId: null,
        roleName: null,
        roleDescription: null,
      },
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      addRolelabel: null,
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      showPerPage: false,
      ///
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
    //pagination methods
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getRole(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getRole(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getRole(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getRole(this.page);
    },
    /////
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    getRole(page = "") {
      let check = this;
      const path = this.$url("USERROLE") + "?page=" + page;
      check.isLoading = true;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res);
          this.roleName = res.data.payload.roleId[0];
          this.total = this.roleName.length;
          this.totRecords = res.data.payload.roleId[1];
          this.pageNo = res.data.payload.roleId[1];
          if (Array.isArray(this.roleName) && this.roleName.length) {
            this.role_data = [];
            let i;
            for (i = 0; i < this.roleName.length; i++) {
              let tempobj = {
                role_id: this.roleName[i][0],
                role_name: this.roleName[i][1],
                role_description: this.roleName[i][2],
                modified_date: "",
              };
              this.role_data.push(tempobj);
              let dateCov;
              dateCov = this.format_date(this.roleName[i][3]);
              check.role_data[i].modified_date = dateCov;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addRoleData(e) {
      e.preventDefault();
      let check = this;
      console.log(this.addRole.roleName);
      if (this.addRole.roleName != "" && this.addRole.roleName != null) {
        this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
          .post(this.$url("USERROLE"), this.addRole)
          .then((result) => {
            console.log(result.data.payload);
            this.message1 = result.data.payload[0].roleId;
            this.message2 = result.data.payload[0].roleName;
            if (this.message2 == null) {
              this.message1 = result.data.payload[0].roleId;
              this.dialog = true;
            } else {
              this.message1 = null;
              this.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) added successfully",
                  text: result.data.payload.message,
                });
              }
              this.getRole();
              this.reset();
            }
            console.log(result);
          });
      }
    },
    addToUpdate(e, atchid) {
      let view = this;
      this.toggleUpdateModal = true;
      view.roleName.forEach(function (o, i) {
        if (o[0] == atchid) {
          view.updateRole.roleName = o[1];
          view.updateRole.roleId = o[0];
          view.updateRole.roleDescription = o[2];
        }
      });
    },
    updateRoleData(e) {
      e.preventDefault();
      let data = this;
      data.isLoading = true;
      if (data.updateRole.roleName != "" && data.updateRole.roleName != null) {
        this.$fetch({ requiresAuth: true, operation: "edit", vueScope: data })
          .put(data.$url("USERROLE"), data.updateRole)
          .then((result) => {
              data.isLoading = false;
              if (result.data.statusCode == 200) {
                data.$swal.fire({
                  icon: "success",
                  title: "Record(s) updated successfully",
                  text: result.data.payload.message,
                });
              this.toggleUpdateModal = false
              }
              this.getRole();
              this.reset();
            });
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      if (e.target.checked) {
        if (this.checked_roleId.indexOf(e.target.value) == -1) {
          this.checked_roleId.push(e.target.value);
        }
      } else {
        this.checked_roleId.splice(
          this.checked_roleId.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_roleId.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteRole(e) {
      e.preventDefault();
      let check = this;
      console.log(this.deleteItems);
      this.$fetch({ requiresAuth: true, operation: "delete", vueScope: check })
        .delete(this.$url("USERROLE"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            if (response.data.payload.result.roles_have_user) {
              let role_names = "";
              response.data.payload.result.roles_have_user.forEach(
                (r, index) => {
                  this.role_data.forEach((d) => {
                    if (d.role_id == parseInt(r)) {
                      if (
                        index ==
                        response.data.payload.result.roles_have_user.length - 1
                      ) {
                        role_names = role_names + d.role_name;
                      } else {
                        role_names = role_names + d.role_name + ", ";
                      }
                    }
                  });
                }
              );
              this.$swal.fire({
                icon: "error",
                title: "Cannot delete role(s) assigned with a user.",
                text: "(" + role_names + ")",
              });
            } else {
              this.$swal.fire({
                icon: "success",
                title: "Success",
                text: response.data.payload.result.msg,
              });
            }
          }
          this.getRole();
          this.deleteItems = [];
        });
    },
    selectAll: function () {
      let role = this.roleName;
      if (this.allSelected == true) {
        if (Array.isArray(role) && role.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < role.length; i++) {
            console.log(role[i]);
            if(role[i][0]!=1){
              this.deleteItems.push(role[i][0]);
            }
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
  },
  watch: {
    "$store.state.Rawdata.current_active_module": function (o) {
      if (o) {
        this.getRole(1);
      }
    },
    page: function (ob) {
      this.getRole(ob);
    },
  },
};
</script>
